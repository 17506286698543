$image-height: 149px;

:root {
  --backgroundThemeColor: var(--white) !important;
}

.update-required-header {
  height: var(--toolbarHeight);
  padding-top: env(safe-area-inset-top);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--borderColor);
}

.update-required-logo-img {
  width: 116px;
}

.update-required-img {
  height: $image-height;
}

.update-required-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - ($image-height + var(--toolbarHeight) + env(safe-area-inset-top)));
}

.update-required-content {
  text-align: center;
  max-width: 400px;
}

.update-required-copy-text {
  color: var(--grey800);
  margin-bottom: 24px;
}
